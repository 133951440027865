@use 'app';
@use 'landing';
@use 'footer';

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");
@import 'variables';

* {
    box-sizing: border-box;
    transition: all 0.1s ease-in-out;
}

html {
    background-color: $black;
}

body {
    margin: 0;
    font-family: 'Roboto Mono', monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url('../img/big-wall.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

// COMMON STYLES

.adam {
    &-light {
        font-weight: 100;
    }

    &-bold {
        font-weight: 100;
        // animation: Font-Weight infinite 5s linear;
    }

    &-glitch-effect {
        animation: glitch 1s linear infinite;

        &:before,
        &:after {
            content: 'N0M4D';
            position: absolute;
            left: 0;
        }

        &:before {
            animation: glitchTop 1s linear infinite;
            clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
        }

        &:after {
            animation: glitchBotom 1.5s linear infinite;
            clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
            -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
        }
    }
}

.social-icons {
    a {
        color: $white;

        &:hover {
            color: $red;
        }

        i {
            font-size: 2rem;
            padding: 0.5rem;
        }
    }
}

// ANIMATIONS

@keyframes Font-Weight {
    from {
        font-weight: 100;
    }

    to {
        font-weight: 600;
    }
}

@keyframes glitch {

    2%,
    64% {
        transform: translate(2px, 0) skew(0deg);
    }

    4%,
    60% {
        transform: translate(-2px, 0) skew(0deg);
    }

    62% {
        transform: translate(0, 0) skew(5deg);
    }
}

@keyframes glitchTop {

    2%,
    64% {
        transform: translate(2px, -2px);
    }

    4%,
    60% {
        transform: translate(-2px, 2px);
    }

    62% {
        transform: translate(13px, -1px) skew(-13deg);
    }
}

@keyframes glitchBotom {

    2%,
    64% {
        transform: translate(-2px, 0);
    }

    4%,
    60% {
        transform: translate(-2px, 0);
    }

    62% {
        transform: translate(-22px, 5px) skew(21deg);
    }
}