@import 'variables';

.landing {
    &-container {
        color: $white;
        display: flex;
        padding: 1rem;
        height: 100%;
    }

    &-header {
        flex: 0.5;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-title {
            font-size: 5rem;

            h1 {
                margin: 0px;
            }
        }

        &-footer {}
    }

    &-sidebar {
        flex: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
            font-size: 2.5rem;
        }
    }
}

/* RESPONSIVENESS */

@media (max-width: 600px) {

    .landing {
        &-header {
            flex: 1;

            &-title {
                font-size: 3rem;
            }
        }

        &-sidebar {
            display: none;
        }
    }
}