@import 'variables';

/* Footer */

#footer {
    align-self: flex-end;
    width: 100%;
    padding: 1.5em 0 0 0;
    color: rgba(255, 255, 255, 0.75);
    cursor: default;
    text-align: center;
    text-transform: none;
    padding-bottom: 1.2rem;
}

#footer .copyright {
    margin: 0;
    padding: 0;
    font-size: 0.9em;
    list-style: none;
}

#footer .copyright li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0.45em;
    padding: 0 0 0 0.85em;
    line-height: 1;
}

#footer .copyright li:first-child {
    border-left: 0;
}

.jaes-made-it {
    text-transform: none;

    a {
        color: $red;
        text-decoration: none;

        &:hover {
            color: $white;
            font-weight: 600;
        }
    }
}